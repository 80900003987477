import request from "@/utils/request";

export const login = (data: any) =>
  request({
    url: "/center/users/login",
    method: "post",
    data: data,
  });
// 用户信息
export const userinfo = (params: any) => {
  return request({
    url: "/center/worker/user/info",
    method: "get",
    params,
  });
};

//判断当前登录人员是否使用默认密码
export const userIsDefaultPwd = () => {
  return request({
    url: "/center/sys/user/isDefaultPwd",
    method: "get",
  });
};

// 出勤总天数
export const checkinAllCount = (params: any) => {
  return request({
    url: "/center/worker/checkin/allCount",
    method: "get",
    params,
  });
};

export const setLoginType = (data: any) => {
  return request({
    url: "/center/users/setLoginType",
    method: "get",
    params: data,
  });
};

// 某月在某个工地的考勤信息
export const checkinRecords = (params: any) => {
  return request({
    url: "/center/worker/checkin/month/records",
    method: "get",
    params,
  });
};

// 某月的总考勤次数统计
export const checkinDays = (params: any) => {
  return request({
    url: "/center/worker/checkin/month/count",
    method: "get",
    params,
  });
};

// 通过code获取微信用户OpenId和accessToken
export const weixinLogin = (params: any) => {
  return request({
    url: "/center/users/weixinLogin",
    method: "get",
    params: params,
  });
};

// 通过code获取微信用户OpenId和accessToken
export const getOpenId = (params: any) => {
  return request({
    url: "/center/sys/wx/oauth/getOpenid/" + params,
    method: "get",
  });
};

export const weixinLogout = () => {
  return request({
    url: "/center/users/weixinLogout",
    method: "get",
  });
};

export const getWeiXinRedirectUrl = () => {
  return request({
    url: "/center/users/getWeiXinRedirectUrl",
    method: "get",
  });
};

export const updatePassword = (data: any) => {
  return request({
    url: "/center/worker/updatePassword",
    method: "post",
    data: data,
  });
};
