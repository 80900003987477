
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/user";
const dsBridge = require("dsbridge");
@Options({
  name: "Myself",
})
export default class extends Vue {
  touch: any = {};
  showDialog = false;
  classList: string[] = ["entry"];
  isGeneralEntry = false;
  isProjectEntry = false;
  userInfo = {};
  checkCount = 0;

  mounted() {
    // this.userInfo = this.$store.state.user.userInfo;
    this.fGetUserInfo();
    this.fGetCheckCount();
    // console.log(this.$store.state.user, "kaugs");
  }
  @Watch("$store.state.user.userInfo", { immediate: true, deep: true })
  currentEnterWatch(userInfo: any) {
    this.isProjectEntry = userInfo.currentEnter === 1 ? true : false;
    this.isGeneralEntry = userInfo.currentEnter === 1 ? false : true;
  }

  fSelectEntry(type: number) {
    api
      .setLoginType({
        enter: type,
      })
      .then((res: any) => {
        if (res.code !== 0) {
          return this.$toast.fail(res.msg);
        } else {
          this.$store.commit("user/SET_CURRENT_ENTER", type);
          this.$store.dispatch("project/actionProjectList");
        }
      });
  }

  async fGetUserInfo() {
    const res: any = await api.userinfo({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (res.code !== 0) {
      return this.$toast.fail(res.msg);
    }
    this.userInfo = res.data;
  }
  //我的考勤
  fGoMyDuty() {
    this.$router.push("/myduty");
  }
  fGoMyProject() {
    this.$router.push("/myproject");
  }
  //修改密码
  goChangePassworld() {
    this.$router.push("/passworld");
  }
  //我的考勤天数
  async fGetCheckCount() {
    const result: any = await api.checkinAllCount({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.checkCount = result.data;
  }
  //登出
  fSignOut() {
    this.$store.commit("user/SET_TOKEN", "");
    this.$store.commit("user/RESET_USERINFO");
    this.$store.commit("project/RESET_PROJECT");
    window.localStorage.removeItem("username");
    window.localStorage.removeItem("password");
    window.localStorage.removeItem("isReadme");
    if (dsBridge.hasNativeMethod("getSharedPreferences")) {
      this.$router.replace("/");
    } else {
      api.weixinLogout().then((res) => {
        api.getWeiXinRedirectUrl().then((res: any) => {
          if (res.code === 0) {
            window.location.href = res.data.redirect;
          }
        });
      });
    }
  }

  onTouch(e: string) {
    if (e === "right") {
      this.classList[0] = "leave";
      sessionStorage.setItem("index", "3");
    }
  }

  touchStart(e: any) {
    const touch = e.touches[0];

    //点击时的位子
    this.touch.startX = touch.pageX;
    this.touch.startY = touch.pageY;
  }
  touchMove(e: any) {
    const touch = e.touches[0];
    //滑动位置和初始位置差
    const deltaX = touch.pageX - this.touch.startX;
    if (Math.abs(deltaX) > 100 && deltaX < 0) {
      this.onTouch("left");
    }
    if (Math.abs(deltaX) > 100 && deltaX > 0) {
      this.onTouch("right");
    }
  }

  animationend() {
    if (this.classList[0] === "leave") {
      this.$router.push("/system/workbench");
    }
  }
}
